.list{
    display: flex;
    align-items: flex-start !important;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px !important;
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid #ddd !important;
    margin-bottom: 0px;
}
.list_div{
    margin-bottom: 35px;
    text-align: left;
    display: flex;
    border-radius: 10px !important;
    flex-direction: column;
    background-color: #fff;
}
.list label{
    text-align: left;
    font-size: 16px;
}
.list p{
    font-size: 14px;
    text-align: left;
    margin: 0px;
}

