
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  font-family: 'Source Sans 3', sans-serif;
  text-align: center;

}

.chatbot-bg{
  background-image: url('./assets/images/chatbot-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.App-logo {
  height: 40vmin; 
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-right: 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chat-bot-button{
  animation: heartBeat;
  animation-duration: 20s;
  /* animation-delay: 2s; */
}
.react-chatbot-kit-chat-container {
  width: 350px;
}
.react-chatbot-kit-chat-inner-container {
  box-shadow: 0 4px 16px rgba(0,0,0,0.25);
      border-radius: 15px;
}

.react-chatbot-kit-chat-message-container{
  background-color: #eaeef3;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0px;
}

.react-chatbot-kit-chat-bot-message{
  color: #000;
  margin-bottom: 30px;
  border-radius: 10px;
  margin-left: 0 !important;
}

#chatbot-loader #chatbot-loader-dot1 {
  fill: #2898ec;
}

#chatbot-loader #chatbot-loader-dot2 {
  fill: #2898ec;
}

#chatbot-loader #chatbot-loader-dot3 {
  fill: #2898ec;
}

.react-chatbot-kit-chat-input{
  font-family: 'Source Sans 3', sans-serif;
}

.react-chatbot-kit-chat-btn-send{
  background-color: #fff;
  color: #ededed;
  border-top: 1px solid #d8d8d8;
}

.react-chatbot-kit-chat-btn-send-icon{
  fill: #b1aaaa;
}

.react-chatbot-kit-chat-input:focus-visible{
  outline: none !important;
}
.react-chatbot-kit-user-chat-message{
  font-family: 'Source Sans 3', sans-serif;
  background-color: #2898ec;
  color: #fff;
  text-align: left !important;
  border-radius: 10px;
  margin-bottom: 35px;
}
.react-chatbot-kit-user-chat-message-arrow{
  color: #2898ec !important;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.react-chatbot-kit-user-chat-message{
  background-color: #2898ec;
    color: #fff;
    border-radius: 10px;
    margin-bottom: 35px;
    /* width: 180px; */
    /* min-width: 20px; */
    text-align: right;
}

.react-chatbot-kit-user-chat-message-arrow{
  border-left: 8px solid #2898ec;
}


@media screen and (max-width:767px) {
  .react-chatbot-kit-chat-container {
    width: calc(100vw - 60px);
  }

}