.header{
    color: #000;
    box-shadow: 0 9.5px 12.7px 0 rgba(0,0,0,.05);
    background-color:#fff;
    padding: 12px 24px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}
.header_avatar{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;
    /* background-image: url(../../assets/images/SIER-BOT-LOGO.png); */
}
.header_text{
    display: flex;
    /* align-items: flex-start; */
    justify-content: flex-start;
    flex-direction: column !important;
}
.avatar{
    height: 40px;
    /* background-color: #2898ec; */
    border-radius: 50%;
    width: 40px;
    /* align-items: center; */
    display: flex;
    justify-content: flex-start;
    color: #fff;
}    

.botname{
    font-size: 20px;
    text-align: left;
}
.status{
    text-align: left;
    font-size: 15px;
    color: #9ba6b3;
}