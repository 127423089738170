.tutorial-section {
    background-color: rgb(24 44 57);
    padding: 40px 0;
    background-color: #f2f2f2;
  }
  
  .tutorial-header {
    margin: 0;
    padding: 30px 0;
    color: #1d1d1d;
    font-size: 1.5rem;
  }
  
  .tutorial-container {
    max-width: 1100px;
    margin: 0 auto;
  }
  
  .tutorial-yt-header {
    color: #fff;
  }
  