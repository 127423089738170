.menu {
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
  }
  
  .menu-link {
    font-weight: 400 !important;
    text-decoration: none;
    color: #0066ff;
    border: 1px solid #0066ff;
    padding: 2px 10px;
    border-radius: 10%;
    margin: 0 5px;
    font-weight: bold;
    background: #fff;
    font-family: 'Source Sans 3', sans-serif;
    border-radius: 16px;
    font-size: 15px;
  }
  