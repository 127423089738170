.botimage{
    width: 50px;
    height: 40px;
    border-radius: 50%;
}
.header_avatar{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    justify-content: flex-start;
    /* background-image: url(../../assets/images/SIER-BOT-LOGO.png); */
}
.bot-avatar{
    height: 40px;
    /* background-color: #2898ec; */
    border-radius: 50%;
    width: 40px;
    /* align-items: center; */
    display: flex;
    justify-content: flex-start;
    color: #fff;
}    
